'use client';

import { Tables } from '@/lib/supabase/database.types';
import { cn, formatDollars } from '@/lib/utils';
import { Asterisk, FlaskConical, Info, Tag } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { Dispatch, PropsWithChildren, SetStateAction, startTransition, useEffect, useState } from 'react';
import { useProgress } from 'react-transition-progress';
import type Stripe from 'stripe';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Switch } from '../ui/switch';
import { fetchUserCoupons, redirectToCheckout } from './actions';
import { PRICES } from './config';
function Coupons({
  coupons,
  selectedCoupon,
  setSelectedCoupon
}: {
  coupons: Stripe.PromotionCode[];
  selectedCoupon: Stripe.PromotionCode | undefined;
  setSelectedCoupon: Dispatch<SetStateAction<Stripe.PromotionCode | undefined>>;
}) {
  const posthog = usePostHog();
  const hasActiveCoupon = coupons.some(coupon => coupon.active);
  const [accordionValue, setAccordionValue] = useState<string | undefined>(hasActiveCoupon ? 'coupons' : undefined);
  useEffect(() => {
    if (hasActiveCoupon) {
      setAccordionValue('coupons');
    }
  }, [hasActiveCoupon]);
  return <Accordion type="single" collapsible value={accordionValue} onValueChange={setAccordionValue} data-sentry-element="Accordion" data-sentry-component="Coupons" data-sentry-source-file="add-credits-dialog.tsx">
      <AccordionItem value="coupons" className="border-none" data-sentry-element="AccordionItem" data-sentry-source-file="add-credits-dialog.tsx">
        <AccordionTrigger className="py-2" data-sentry-element="AccordionTrigger" data-sentry-source-file="add-credits-dialog.tsx">
          <span className="flex items-center gap-2">
            <Tag className="size-4 shrink-0" data-sentry-element="Tag" data-sentry-source-file="add-credits-dialog.tsx" />
            Available Coupons
          </span>
        </AccordionTrigger>
        <AccordionContent className="-mb-3 flex flex-row gap-2 overflow-y-auto pb-3" data-sentry-element="AccordionContent" data-sentry-source-file="add-credits-dialog.tsx">
          {coupons.map(coupon => <button key={coupon.id} className={cn('w-full shrink-0 rounded-2xl border border-dashed border-foreground/50 p-4 text-start animate-in fade-in slide-in-from-bottom-4 disabled:opacity-50', selectedCoupon?.id === coupon.id ? '' : 'opacity-50', coupons.length > 1 && 'w-3/4')} onClick={() => {
          posthog.capture(selectedCoupon?.id === coupon.id ? 'coupon_deselected' : 'coupon_selected', {
            coupon: coupon.id,
            coupon_name: coupon.code
          });
          setSelectedCoupon(selectedCoupon?.id === coupon.id ? undefined : coupon);
        }} disabled={!coupon.active}>
              <h3 className="flex items-center gap-2 text-base font-semibold">
                {coupon.active && <Switch checked={selectedCoupon?.id === coupon.id} />}
                {coupon.coupon.name}
                {coupon.coupon.name === 'Beta Tester Reward' && <FlaskConical className="ms-auto size-6 shrink-0 text-fuchsia-500" strokeWidth={2} />}
              </h3>
              <p className="my-3 text-xl font-bold text-primary">
                <span className="rounded-lg bg-green-500 px-2 py-1 font-mono">
                  $5
                </span>{' '}
                <span className="italic">Discount</span>
              </p>
              {coupon.times_redeemed >= (coupon.max_redemptions ?? 9999) ? <span className="rounded-full border px-2 py-1">REDEEMED</span> : coupon.expires_at && new Date(coupon.expires_at * 1000) < new Date() ? <span className="rounded-full border px-2 py-1">EXPIRED</span> : !coupon.active ? <span className="rounded-full border px-2 py-1">INACTIVE</span> : <p className="text-sm text-muted-foreground">
                  Valid until{' '}
                  {new Date(coupon.expires_at! * 1000).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            })}
                </p>}
            </button>)}
        </AccordionContent>
      </AccordionItem>
    </Accordion>;
}
export function AddCreditsDialog({
  billingAccount,
  open,
  setOpen,
  children
}: PropsWithChildren<{
  billingAccount: Tables<'billing_accounts'>;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}>) {
  const [coupons, setCoupons] = useState<Stripe.PromotionCode[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<Stripe.PromotionCode>();
  useEffect(() => {
    fetchUserCoupons().then(d => {
      setCoupons(d);
      setSelectedCoupon(d.find(c => c.active));
    }).catch(() => {});
  }, []);
  const startProgress = useProgress();
  const posthog = usePostHog();
  useEffect(() => {
    if (open) {
      posthog.capture('payment_modal_opened');
    }
  }, [open, posthog]);
  return <Dialog open={open} onOpenChange={o => {
    setOpen?.(o);
    if (!o) posthog.capture('payment_modal_closed');
  }} data-sentry-element="Dialog" data-sentry-component="AddCreditsDialog" data-sentry-source-file="add-credits-dialog.tsx">
      {children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <DialogContent onOpenAutoFocus={e => e.preventDefault()} data-sentry-element="DialogContent" data-sentry-source-file="add-credits-dialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="add-credits-dialog.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="add-credits-dialog.tsx">Add credits</DialogTitle>
          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="add-credits-dialog.tsx">
            Select the amount of credits you want to add to your account.
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-2 gap-2 gap-y-5">
          {PRICES.map(price => {
          let priceAfterCoupon = price.amount;
          if (selectedCoupon && price.amount) {
            if (selectedCoupon.coupon.amount_off) {
              priceAfterCoupon = price.amount - selectedCoupon.coupon.amount_off / 100;
            } else if (selectedCoupon.coupon.percent_off) {
              priceAfterCoupon = price.amount - price.amount * selectedCoupon.coupon.percent_off / 100;
            }
          }
          selectedCoupon?.coupon.amount_off;
          return <button key={price.priceID} className={cn('relative flex items-center justify-center gap-2 rounded-xl border p-3 text-xl font-bold text-muted-foreground shadow transition-all duration-300 disabled:bg-muted disabled:opacity-50 disabled:shadow-none hover:enabled:-translate-y-px hover:enabled:scale-[101%]', !price.amount && 'col-span-2 border-border', selectedCoupon && '', price.amount == 20 && 'col-span-2 border-amber-600', price.amount == 50 && 'col-span-2 border-fuchsia-600')} disabled={price.amount === undefined && !!selectedCoupon} onClick={() => startTransition(async () => {
            posthog.capture('payment_option_selected', {
              amount: price.amount ?? 'custom',
              promo_code: selectedCoupon?.code,
              coupon: selectedCoupon?.coupon.name,
              coupon_redemption_number: selectedCoupon?.coupon.times_redeemed,
              promo_code_redemption_number: selectedCoupon?.times_redeemed
            });
            startProgress();
            await redirectToCheckout({
              priceId: price.priceID,
              feeId: priceAfterCoupon && priceAfterCoupon > 0 ? price.feeId : undefined,
              redirectPath: new URL(window.location.href).pathname,
              billingAccountId: billingAccount.id,
              discount: selectedCoupon ? {
                promotion_code: selectedCoupon.id
              } : undefined
            });
          })}>
                {price.tag && <span className={cn('absolute left-4 top-0 z-10 -translate-y-1/2 rounded-full border bg-background px-2 py-0.5 text-xs font-semibold text-primary-foreground shadow')}>
                    <span className={cn(price.amount == 20 && 'bg-gradient-to-br from-amber-400 to-orange-500 bg-clip-text font-bold text-transparent dark:from-amber-500 dark:to-orange-600', price.amount == 50 && 'bg-gradient-to-br from-fuchsia-400 to-indigo-500 bg-clip-text font-bold text-transparent dark:from-fuchsia-500 dark:to-indigo-600')}>
                      {price.tag}
                    </span>
                  </span>}
                {price.amount ? <>
                    <span className={cn(price.amount == 20 && 'bg-gradient-to-br from-amber-500 to-orange-600 bg-clip-text font-bold text-transparent', price.amount == 50 && 'bg-gradient-to-br from-fuchsia-500 to-indigo-700 bg-clip-text font-bold text-transparent dark:to-indigo-600')}>
                      {formatDollars(price.amount)}
                    </span>
                    {selectedCoupon && <>
                        for{' '}
                        <span className="font-semibold italic text-green-500">
                          {formatDollars(priceAfterCoupon!)}
                        </span>
                      </>}
                  </> : <span>
                    Custom amount
                    <Asterisk className="ms-1 inline size-3 align-top" />
                  </span>}
              </button>;
        })}
        </div>

        <Coupons coupons={coupons} selectedCoupon={selectedCoupon} setSelectedCoupon={setSelectedCoupon} data-sentry-element="Coupons" data-sentry-source-file="add-credits-dialog.tsx" />

        <div className="flex items-start gap-2 text-sm text-muted-foreground">
          <Info className="mt-1 size-4 shrink-0" data-sentry-element="Info" data-sentry-source-file="add-credits-dialog.tsx" />A 5% + $0.50 transaction fee
          will be charged on top of the amount you choose.
        </div>

        <div className="flex items-start gap-1 text-xs text-muted-foreground">
          <Asterisk className="size-3 shrink-0" data-sentry-element="Asterisk" data-sentry-source-file="add-credits-dialog.tsx" />
          Coupons and promo codes can&apos;t be used with custom amounts. The
          fee will be deducted from the amount you choose.
        </div>
      </DialogContent>
    </Dialog>;
}