const _TEST_PRICES = [
  {
    amount: 5,
    priceID: 'price_1Q30sUBFk20O8YYjw5ICJR1s',
    feeId: 'price_1Q31NhBFk20O8YYj0U1qEYdI',
  },
  {
    amount: 10,
    priceID: 'price_1Q31XuBFk20O8YYjqYXxLSoK',
    feeId: 'price_1Q31NhBFk20O8YYj8IylYN27',
  },
  {
    amount: 20,
    priceID: 'price_1Q31XWBFk20O8YYj5OmMrBZb',
    feeId: 'price_1Q31NhBFk20O8YYjwODPnQEB',
    tag: 'Most popular',
  },
  {
    amount: 50,
    priceID: 'price_1Q31YMBFk20O8YYjNNft5Weg',
    feeId: 'price_1Q31NhBFk20O8YYjMdBPXg8X',
    tag: 'Lowest fees',
  },
  {
    amount: undefined,
    priceID: 'price_1Q30sUBFk20O8YYjxYhOvtWn',
    feeId: undefined,
  },
]
const _TEST_TOP_UP_PRODUCT_ID = 'prod_QuqZ06yZUVM03j'
const _TEST_FEE_PRODUCT_ID = 'prod_Qur5n6A8y3rp2E'

const _PROD_PRICES = [
  {
    amount: 5,
    priceID: 'price_1Q5uLXBFk20O8YYjEbDIk441',
    feeId: 'price_1Q5uLcBFk20O8YYjFaQcoJaJ',
  },
  {
    amount: 10,
    priceID: 'price_1Q5uLXBFk20O8YYj1MPI4t3J',
    feeId: 'price_1Q5uLcBFk20O8YYjBa1FYFBd',
  },
  {
    amount: 20,
    priceID: 'price_1Q5uLXBFk20O8YYjpXSb9c2T',
    feeId: 'price_1Q5uLcBFk20O8YYjT0s3yEau',
    tag: 'Most popular',
  },
  {
    amount: 50,
    priceID: 'price_1Q5uLXBFk20O8YYj4lUeEU8S',
    feeId: 'price_1Q5uLcBFk20O8YYjEFSGCDKB',
    tag: 'Lowest fees',
  },
  {
    amount: undefined,
    priceID: 'price_1Q5uLXBFk20O8YYjihMFrUc8',
    feeId: undefined,
  },
]
const _PROD_TOP_UP_PRODUCT_ID = 'prod_Qxq143JIhTyfgM'
const _PROD_FEE_PRODUCT_ID = 'prod_Qxq1CuHe5Gyir3'

let PRICES = _TEST_PRICES
let TOP_UP_PRODUCT_ID = _TEST_TOP_UP_PRODUCT_ID
let FEE_PRODUCT_ID = _TEST_FEE_PRODUCT_ID

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  PRICES = _PROD_PRICES
  TOP_UP_PRODUCT_ID = _PROD_TOP_UP_PRODUCT_ID
  FEE_PRODUCT_ID = _PROD_FEE_PRODUCT_ID
}

export { FEE_PRODUCT_ID, PRICES, TOP_UP_PRODUCT_ID }
